<template>
  <main>
    <div class="fixed-holder-btn" v-if="demoLink">
      <a href="javascript:void(0)" @click="openDemo" class="fixed-btn infinite animated pulse">
        <span class="btn-text">{{ demo_text }}</span>
      </a>
    </div>

    <Busked></Busked>
    <MenuHeader :s_info="s_info" @auth="fetchAccount"/>

    <div class="bottom-nav">
      <ul>
        <li :class="{'disable': !hasHistory}">
          <a href="javascript:void(0)" @click="$filters.goBack">
            <img src="/images/icons/back.svg">
          </a>
        </li>
        <li>
          <router-link :to="{name: 'home'}">
            <img src="/images/icons/Home.svg">
          </router-link>
        </li>
        <li class="quick-card-sticker-li">
          <div id="quick_card_panel_sticker">
            <div class="quick-card-sticker-holder">
              <a href="javascript:void(0);">
                <span class="notification">
                  {{ buskedLength }}
                </span>
                <img src="/images/icons/bag-outline-menu.svg">
              </a>
              <div class="value">{{ $filters.money(total) }} {{ $store.getters.getSettings.symbol }}</div>
            </div>
          </div>
        </li>
        <li>
          <a href="javascript:void(0);" class="show-menu-btn">
            <span class="show-menu-btn-holder">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="login-trigger">
            <img src="/images/icons/user-filled.svg">
          </a>
        </li>
      </ul>
    </div>

    <router-view/>
    <div class="personal-sidebar-bg"></div>
  </main>
  <footer>
    <div class="footer-holder">
      <div class="footer-contacts">
        <div class="holder">
          <h5>{{ $static('footer.tot-contacts', 'Контакти') }}</h5>
          <div class="contact-box">
            <div class="contact-item" v-if="settings.email">
              <div class="name">{{ $static('footer.email', 'Электронна пошта') }}:</div>
              <a href="javascript:void(0)" v-if="webAppStatus" @click="openInBrowser()">{{ settings.email }}</a>
              <a :href="'mailto:'+settings.email" v-else>{{ settings.email }}</a>
            </div>
            <div class="contact-item" v-if="settings.phone && webAppStatus">
              <div class="name">{{ $static('footer.phone', 'Телефон') }}:</div>
              <!--              <a :href="'tel:'+settings.phone">{{settings.phone}}</a>-->
              <template v-for="(value,key) in phones" :key="key">
                <a href="javascript:void(0)" v-html="value.text" @click="openInBrowser()" v-if="value.isNumber"></a>
                <p v-else v-html="value.text">
                </p>
              </template>
            </div>
            <div class="contact-item" v-else-if="settings.phone">
              <div class="name">{{ $static('footer.phone', 'Телефон') }}:</div>
              <!--              <a :href="'tel:'+settings.phone">{{settings.phone}}</a>-->
              <template v-for="(value,key) in phones" :key="key">
                <a :href="'tel:'+value.real" v-html="value.text" target="_blank" v-if="value.isNumber"></a>
                <p v-else v-html="value.text">
                </p>
              </template>
            </div>

            <div class="contact-item" v-if="(settings.instagram || settings.telegram) && webAppStatus">
              <div class="name">{{ $static('footer.social', 'Соціальні мережі') }}:</div>
              <a v-if="settings.instagram" href="javascript:void(0)" @click="openInBrowser(settings.instagram)">#instagram</a>
              <a v-if="settings.telegram" :href="settings.telegram">@telegram</a>
            </div>
            <div class="contact-item" v-else-if="(settings.instagram || settings.telegram)">
              <div class="name">{{ $static('footer.social', 'Соціальні мережі') }}:</div>
              <a v-if="settings.instagram" :href="settings.instagram">#instagram</a>
              <a v-if="settings.telegram" :href="settings.telegram">@telegram</a>
            </div>

          </div>
        </div>
      </div>
      <div class="footer-links">
        <div class="holder">
          <ul>
            <li>
              <router-link :to="{name:'about'}">
                <span class="text">{{ $static('menu.about', 'Про нас') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'delivery'}">
                <span class="text">{{ $static('menu.delivery', 'Доставка та оплата') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'policy'}">
                <span class="text">{{ $static('menu.policy', 'Політика конфіденційності') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'dostup'}">
                <span class="text">{{ $static('menu.dostup', 'Політика доступності') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'offerta'}">
                <span class="text">{{ $static('menu.offerta', 'Публічна оферта') }}</span>
              </router-link>
            </li>
          </ul>
          <div class="logos">
            <img src="/images/icons/mastercard.svg" alt="mastercard">
            <img src="/images/icons/visa.svg" alt="visa">
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="copy">
          &copy; {{ $static('footer.copy', `2023 Копіювання матеріалів сайту без дозволу заборонено`) }}
        </div>

        <!--        <div class="develop-by" v-if="settings && settings.watter_mark">-->
        <!--          <a href="https://ux.im/">-->
        <!--            <div class="name"><img src="/images/developedby.svg" alt="uxlove studio" class="developedby"></div>-->
        <!--            <img src="/images/uxlove-logo.svg" alt="uxlove studio"></a>-->
        <!--        </div>-->
      </div>
    </div>
  </footer>

  <!--  <div class="modal custom-modal fade" id="consult_modal" tabindex="-1" role="dialog" aria-hidden="true">-->
  <!--    <div class="modal-dialog modal-dialog-centered" role="document">-->
  <!--      <div class="modal-content">-->
  <!--        <div class="modal-header">-->
  <!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
  <!--            <img src="/images/icons/close.svg" alt="">-->
  <!--          </button>-->
  <!--        </div>-->
  <!--        <div class="modal-body">-->
  <!--          <h2>{{ $static('modal-callback.title', 'Отримати консультацію') }}</h2>-->
  <!--          <p>{{-->
  <!--              $static('modal-callback.text', `Введіть свій номер телефону нижче,-->
  <!--            і наш менеджер зв’яжеться з Вами-->
  <!--            за декілька хвилин`)-->
  <!--            }}</p>-->
  <!--          <form>-->
  <!--            <div class="form-group" :class="{'error': errors['phone']}">-->
  <!--              <input type="text" class="form-control custom-input" @focus="delError('phone')"-->
  <!--                     placeholder="+380998787858"-->
  <!--                     v-model="callbackPhone">-->
  <!--              <p class="help-text" v-for="(err,key) in errors['phone']" :key="key">{{ err }}</p>-->
  <!--            </div>-->
  <!--            <div class="button-holder">-->
  <!--              <a href="javascript:void(0)" class="default-btn" @click="sendCallback">-->
  <!--                <span class="btn-text">{{ $static('modal-callback.button', 'Отримати консультацію') }}</span>-->
  <!--                <img src="/images/icons/send-light.svg" alt="">-->
  <!--              </a>-->
  <!--            </div>-->
  <!--          </form>-->
  <!--          <div class="attention-apply">{{ $static('modal-callback.info1', 'При кліці на кнопку я погоджуюся з') }}-->
  <!--            <router-link :to="{name: 'policy'}">-->
  <!--              {{ $static('modal-callback.info2', `політикою обробки персональних даних`) }}-->
  <!--            </router-link>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <ModalDeliveryMap/>

</template>

<script>
import ModalDeliveryMap from '@/components/ModalDeliveryMap';
import axios from 'axios'
import Busked from './components/Busked';
import MenuHeader from './components/MenuHeader';

export default {
  components: {ModalDeliveryMap, MenuHeader, Busked},
  data() {
    return {
      callbackPhone: '',
      errors       : {},
      demoLink     : undefined,
      demo_text    : undefined
    }
  },
  methods: {
    openDemo() {
      if (window.Telegram?.WebApp?.initData) {
        window.Telegram.WebApp.openTelegramLink(this.demoLink)
        window.Telegram.WebApp.close()
        return;
      }
      window.location.href = this.demoLink;
    },
    delError(key) {
      delete this.errors[key]
    },
    validate() {
      this.errors = {};
      if (this.callbackPhone.length < 8) {
        this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
        return false
      }
      return true;
    },
    fetchSettings() {
      return fetch('/public/settings').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setSettings', data);
              // let check = ['code1', 'code2', 'code3'];
              // check.forEach(x => {
              //   if (data[x]) {
              //     let elem           = document.createElement('div');
              //     elem.style.display = 'none';
              //     elem.innerHTML     = data[x];
              //
              //     // let newHTML = document.querySelector('head').innerHTML;
              //     // newHTML += data[x];
              //     // document.querySelector('head').innerHTML = newHTML;
              //
              //     document.querySelector('head').appendChild(elem)
              //   }
              // });
            }

          })
    },

    fetchAccount() {
      let ok        = false
      let userInfo  = localStorage.getItem('user_info')
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true
      }
      let body = {}
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        ok        = true
      }
      if (!ok) {
        return new Promise(resolve => resolve())
      }

      return fetch(
          '/public/account',
          {
            method : 'POST',
            body   : JSON.stringify(body),
            headers: headers
          }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.$store.commit('setAccount', json.result)
        }
        if (json?.error) {
          console.log(json)
          window.localStorage.removeItem('user_info')
          this.$router.push({'name': 'login'})
        }
      })
    },
    getPlugins() {
      return fetch('/public/plugin').then(response => response.json()).then(data => {
        data.forEach(x => {
          let str            = x.type
          let capitalizeType = [...str][0].toUpperCase() + [...str].slice(1).join('');
          this.$store.commit(`set${capitalizeType}`, x.list);
          // this[x.type] = x.list;
          if (x.list.length) {
            let id = x.list[0].id;
            switch (x.type) {
            case 'payment':
              this.$store.commit('setDefaultPayment', id)
              break;
            case 'delivery':
              this.$store.commit('setDefaultDelivery', id)
              break
            }
          }
        });

      });
    },
    getOptions() {
      return axios.get('/public/options').then(response => response.data).then(data => {
        data.result.forEach(o => {
          /*eslint no-case-declarations: "off"*/
          switch (o.option_id) {
          case 'tags':
            this.$store.commit('setTags', o.list);
            break;
          case 'category':
            this.$store.commit('setCategories', o.list);
            break;
          default:
            this.$store.commit('setOptions', o);
            break;
          }
        });

        if (data.settings) {
          this.$store.commit('setOptionSettings', data.settings);
        }
      });
    },
    fetchStorehouse() {
      return fetch('/public/storehouse').then(response => response.json()).then(data => {
        if (data?.length) {
          this.$store.commit('setStorehouse', data)
        }
      });
    },
    isWebApp() {
      let initData = localStorage.getItem('initData');
      return (initData && initData.length) || (!!window.Telegram && !!window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length)
    },
    openInBrowser(link) {
      if (link) {
        window.Telegram.WebApp.openLink(link)
        return
      }
      window.Telegram.WebApp.openLink('https://' + window.location.hostname)
    }
  },
  mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    if (params.code) {
      this.$Code(params.code)
    }
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.enableClosingConfirmation();
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(this.$filters.goBack);
    }
    let json = localStorage.getItem('user_info')
    json     = JSON.parse(json)
    if (!json) {
      localStorage.removeItem('user_info')
    } else {
      this.$store.commit('setUserInfo', json)
    }

    window.pos    = {top: 0, left: 0, x: 0, y: 0};
    window.ele    = undefined;
    window.change = false;
    if (location.hostname === 'uxpizza.ux.im') {
      this.demoLink  = 'https://t.me/uxwebbot?start=demo'
      this.demo_text = this.$static('demo_btn_create.create', 'Створити безкоштовн')
    }
    if (location.hostname === 'horeca.ux.im') {
      this.demoLink  = 'https://t.me/UXLoveBot?start=horeca'
      this.demo_text = this.$static('demo_btn_create.create2', 'Замовити в 1 клік')
    }

    function isTouchDevice() {
      return (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0));
    }

    window.istouch          = isTouchDevice();
    window.mouseDownHandler = function (e) {
      window.pos = {
        // The current scroll
        left: window.ele.scrollLeft,
        top : window.ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY
      };

      // Change the cursor and prevent user from selecting the text
      // ele.style.cursor = 'grabbing';

      if (!window.istouch) {
        window.ele.style.userSelect = 'none';
        window.change               = false;
        document.addEventListener('mousemove', window.mouseMoveHandler);
        document.addEventListener('mouseup', window.mouseUpHandler);
      }
    };

    window.mouseMoveHandler = function (e) {
      // console.log('mouseMoveHandler', e)
      // How far the mouse has been moved
      window.change         = true;
      const dx              = e.clientX - window.pos.x;
      const dy              = e.clientY - window.pos.y;
      // Scroll the element
      window.ele.scrollTop  = window.pos.top - dy;
      window.ele.scrollLeft = window.pos.left - dx;
    };

    window.clearChange    = function (e) {
      window.change = false;
    }
    window.mouseUpHandler = function () {
      // console.log('mouseUpHandler')
      document.removeEventListener('mousemove', window.mouseMoveHandler);
      document.removeEventListener('mouseup', window.mouseUpHandler);
      setTimeout(window.clearChange, 300)
    };

    document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

    if (window.Telegram) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
      // document.getElementById('subtitle').innerText = navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', function (e) {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        }, {passive: false});
        window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
      }
      if (window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length) {
        localStorage.setItem('initData', window.Telegram.WebApp.initData)
      }
    }
    this.$store.commit('setIsWebApp', this.isWebApp());

    (function ($) {
      'use strict';
      $('#category_panel').stick_in_parent({
                                             offset_top: 0
                                           });
      $('#quick_card_panel_sticker a').on('click', function () {
        $('body').toggleClass('no-scroll');
        $('.overlay-menu').removeClass('active');
        $('.show-menu-btn').removeClass('open');
        $('.open-nav').removeClass('is-visible');
        $('#quick_cart').toggleClass('opened');
        $('.quick-card-sticker-li').toggleClass('active');
        $('.frame-modal-wrapper-bg').toggleClass('is-visible');
        return false;
      });
      $('.login-trigger').on('click', function () {
        $('.personal_area-sidebar').toggleClass('opened');
        $('body').toggleClass('no-scroll');
        $('.show-menu-btn').removeClass('open');
        $('#quick_cart').removeClass('opened');
        $('.overlay-menu').removeClass('active');
        $('.quick-card-sticker-li').removeClass('active');
        $('.personal-sidebar-bg').toggleClass('is-visible');
        $('.open-nav').removeClass('is-visible');
        return false;
      });
      $('.close-personal-sidebar').on('click', function () {
        $('.personal_area-sidebar').removeClass('opened');
        $('body').removeClass('no-scroll');
        $('.personal-sidebar-bg').removeClass('is-visible');
        return false;
      });
      $('.personal-sidebar-bg').on('click', function () {
        $('.personal_area-sidebar').removeClass('opened');
        $('body').removeClass('no-scroll');
        $(this).removeClass('is-visible');
        return false;
      });

      $('.show-menu-btn').on('click', function () {
        $('.overlay-menu').toggleClass('active');
        $(this).toggleClass('open');
        $('body').toggleClass('no-scroll');
        $('#quick_cart').removeClass('opened');
        $('.quick-card-sticker-li').removeClass('active');
        $('.frame-modal-wrapper-bg').removeClass('is-visible');
        $('.open-nav').toggleClass('is-visible');
        return false;
      });
      $('.overlay-menu .close-menu-btn').on('click', function () {
        $('.overlay-menu').removeClass('active');
        $('.show-menu-btn').removeClass('open');
        $('body').removeClass('no-scroll');
        $('.open-nav').removeClass('is-visible');
        return false;
      });

      $('.open-nav').on('click', function () {
        $('.overlay-menu').removeClass('active');
        $('.show-menu-btn').removeClass('open');
        $('body').removeClass('no-scroll');
        $(this).removeClass('is-visible');
        return false;
      });
      $(function () {
        if ($(window).scrollTop() >= 100) {
          $('.fixed-holder-btn').addClass('stickybottom');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 100) {
            $('.fixed-holder-btn').addClass('stickybottom');
          } else {
            $('.fixed-holder-btn').removeClass('stickybottom');
          }
        });
      });

      $(function () {
        if ($(window).scrollTop() >= 300) {
          $('.fixed-options-panel').addClass('stickybottom');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 300) {
            $('.fixed-options-panel').addClass('stickybottom');
          } else {
            $('.fixed-options-panel').removeClass('stickybottom');
          }
        });
      });
    })(window.$);

    let options        = this.getOptions();
    let plugins        = this.getPlugins();
    let account        = this.fetchAccount();
    window.accountLoad = account;
    let settings       = this.fetchSettings();
    let storehouse     = this.fetchStorehouse();

    Promise.all([options, account, settings, plugins, storehouse]).then(() => {
      window.$('.preloader-page').removeClass('proccess-load')
    })

    let check = localStorage.getItem('__myLoc')
    if (check) {
      try {
        this.$store.commit('setPosition', JSON.parse(check))
      } catch (e) {
        console.error(e)
      }
    }
    let today     = new Date();
    let yesterday = new Date(today.getTime());
    yesterday.setDate(today.getDate() - 1);
    let pos = this.$store.getters.getPosition
    if (!pos || pos.time < yesterday.getTime()) {
      fetch('https://ipinfo.io/json')
          .then(response => {
            if (response.status === 200) {
              return response.json()
            }
            throw response.statusText
          })
          .then(data => {
            this.$store.commit('setPosition', {time: today.getTime(), loc: data.loc})
            localStorage.setItem('__myLoc', JSON.stringify({time: today.getTime(), loc: data.loc}))
          })
          .catch(err => {
            fetch('https://ipinfo.io?token=84be2035a1b97d')
                .then(response => response.json())
                .then(data => {
                  this.$store.commit('setPosition', {time: today.getTime(), loc: data.loc})
                  localStorage.setItem('__myLoc', JSON.stringify({time: today.getTime(), loc: data.loc}))
                })
          })
    }
  },
  computed: {
    webAppStatus() {
      return this.$store.getters.isWebApp
    },
    hasHistory() {
      return window.history.length > 2
    },
    settings() {
      return this.$store.getters.getSettings
    },
    logo() {
      return this.settings && this.settings.logo ? this.settings.logo : ''
    },
    initData() {
      return window.location.href
    },
    s_info() {
      return this.settings && this.settings.s_info ? this.settings.s_info : false
    },
    phones() {
      return this.settings && this.settings.phone ?
          this.settings.phone.split(',').map(x => x.trim())
              .map(x => {
                return {real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+')}
              }) : false
    },
    busked() {
      return this.$store.getters.getBusked
    },
    buskedLength() {
      return this.busked.reduce((prev, x) => prev + x.length, 0)
    },
    total() {
      return this.$store.getters.getBuskedTotal
    },
    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    }
    // local() {
    //   return this.$local()
    // },
    // dynamics() {
    //   return this.$dynamics()
    // }
  }

}
</script>
