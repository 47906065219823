<template>
  <li class="catalog-item" v-if="categoryObject?.info?.url">
    <div class="item-holder" :class="{'not-available': !item.stock}">
      <div class="product-label label-new" v-if="isHot">
        <span class="label-text">New</span>
      </div>
      <div class="product-label label-sale" v-if="item.price_old">
        <div class="tile_label tile_sale">-{{ getDiscount }}%</div>
      </div>

      <router-link :to="{name: 'Item', params:{ category: categoryObject.info.url, id: item.id}}"
                   class="image bg-cover"
                   :style="{'background-image': $filters.backgroundImage(item.preview)}">
        <div class="value-info" v-if="item.advanced?.modList?.length">
          <span class="value">{{ weight }}</span>
        </div>
      </router-link>

      <div class="item-content">
        <div class="product-name">
          <router-link :to="{name: 'Item', params:{ category: categoryObject.info.url, id: item.id}}">
            {{ $dynamic(item, item.title, 'title') }}
          </router-link>

          <div class="spicy" v-if="spicy > 0" :class="'spicy'+spicy"></div>
        </div>
        <div class="desc">{{ $dynamic(item, item.advanced.short_description, 'short_description') }}</div>
        <div class="particular">
          <ul>
            <li v-for="(i, index) in ingredient" :key="index">
              <img v-if="i?.image" :src="i.image" alt="">
              <span class="text">{{ i.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="options">
        <div class="price-box">
          <div class="price-element" v-if="price > 0"> {{ $filters.money(price) }} {{ $store.getters.getSettings.symbol }}</div>
          <div class="price-old" v-if="price_old"> {{ $filters.money(price_old) }} {{ $store.getters.getSettings.symbol }}
          </div>
        </div>
        <div class="right-options" v-if="item.stock">
          <div class="size-holder" v-if="item.advanced?.modList?.length > 1">
            <div class="size-radio-group">
              <div class="size-radio" v-for="(m, index) in item.advanced.modList" :key="index">
                <input :id="'item'+item.id+'_'+index" type="radio" v-model="order.selectedMod" :value="index">
                <label :for="'item'+item.id+'_'+index">{{ m.name }}</label>
              </div>
            </div>
          </div>

          <router-link :to="{name: 'Item', params:{ category: categoryObject.info.url, id: item.id}}" v-if="isOptions" class="default-btn">
            <span class="btn-text">Замовити</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.33301 11.0417C8.67819 11.0417 8.95801 11.3215 8.95801 11.6667V13.3333C8.95801 13.6785 8.67819 13.9583 8.33301 13.9583C7.98783 13.9583 7.70801 13.6785 7.70801 13.3333V11.6667C7.70801 11.3215 7.98783 11.0417 8.33301 11.0417Z"
                  fill="white"/>
              <path
                  d="M12.2913 11.6667C12.2913 11.3215 12.0115 11.0417 11.6663 11.0417C11.3212 11.0417 11.0413 11.3215 11.0413 11.6667V13.3333C11.0413 13.6785 11.3212 13.9583 11.6663 13.9583C12.0115 13.9583 12.2913 13.6785 12.2913 13.3333V11.6667Z"
                  fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.04094 2.81248C8.21353 2.51355 8.11111 2.13131 7.81217 1.95872C7.51324 1.78613 7.131 1.88855 6.95841 2.18748L5.29174 5.07423C5.26677 5.11748 5.24756 5.16247 5.23386 5.20832H4.99967C3.73402 5.20832 2.70801 6.23433 2.70801 7.49998C2.70801 8.21592 3.03631 8.85518 3.55059 9.27543L4.10637 13.096L4.47865 14.8403C4.70171 15.8854 5.55487 16.6792 6.61331 16.8265C8.86006 17.1391 11.1393 17.1391 13.386 16.8265C14.4445 16.6792 15.2976 15.8854 15.5207 14.8403L15.893 13.096L16.4488 9.27543C16.963 8.85518 17.2913 8.21592 17.2913 7.49998C17.2913 6.23433 16.2653 5.20832 14.9997 5.20832H14.7655C14.7518 5.16247 14.7326 5.11748 14.7076 5.07423L13.0409 2.18748C12.8684 1.88855 12.4861 1.78613 12.1872 1.95872C11.8882 2.13131 11.7858 2.51355 11.9584 2.81248L13.3416 5.20832H6.6577L8.04094 2.81248ZM15.1109 9.789C15.074 9.79076 15.037 9.79165 14.9997 9.79165H4.99967C4.96239 9.79165 4.92531 9.79076 4.88845 9.789L5.33742 12.8753L5.70112 14.5794C5.81444 15.1103 6.24786 15.5136 6.78558 15.5884C8.91804 15.8851 11.0813 15.8851 13.2138 15.5884C13.7515 15.5136 14.1849 15.1103 14.2982 14.5794L14.6619 12.8753L15.1109 9.789ZM3.95801 7.49998C3.95801 6.92469 4.42438 6.45832 4.99967 6.45832H14.9997C15.575 6.45832 16.0413 6.92469 16.0413 7.49998C16.0413 8.07528 15.575 8.54165 14.9997 8.54165H4.99967C4.42438 8.54165 3.95801 8.07528 3.95801 7.49998Z"
                    fill="white"/>
            </svg>
          </router-link>
          <a href="javascript:void(0)" v-else @click="buy" class="default-btn">
            <span class="btn-text">Замовити</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.33301 11.0417C8.67819 11.0417 8.95801 11.3215 8.95801 11.6667V13.3333C8.95801 13.6785 8.67819 13.9583 8.33301 13.9583C7.98783 13.9583 7.70801 13.6785 7.70801 13.3333V11.6667C7.70801 11.3215 7.98783 11.0417 8.33301 11.0417Z"
                  fill="white"/>
              <path
                  d="M12.2913 11.6667C12.2913 11.3215 12.0115 11.0417 11.6663 11.0417C11.3212 11.0417 11.0413 11.3215 11.0413 11.6667V13.3333C11.0413 13.6785 11.3212 13.9583 11.6663 13.9583C12.0115 13.9583 12.2913 13.6785 12.2913 13.3333V11.6667Z"
                  fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.04094 2.81248C8.21353 2.51355 8.11111 2.13131 7.81217 1.95872C7.51324 1.78613 7.131 1.88855 6.95841 2.18748L5.29174 5.07423C5.26677 5.11748 5.24756 5.16247 5.23386 5.20832H4.99967C3.73402 5.20832 2.70801 6.23433 2.70801 7.49998C2.70801 8.21592 3.03631 8.85518 3.55059 9.27543L4.10637 13.096L4.47865 14.8403C4.70171 15.8854 5.55487 16.6792 6.61331 16.8265C8.86006 17.1391 11.1393 17.1391 13.386 16.8265C14.4445 16.6792 15.2976 15.8854 15.5207 14.8403L15.893 13.096L16.4488 9.27543C16.963 8.85518 17.2913 8.21592 17.2913 7.49998C17.2913 6.23433 16.2653 5.20832 14.9997 5.20832H14.7655C14.7518 5.16247 14.7326 5.11748 14.7076 5.07423L13.0409 2.18748C12.8684 1.88855 12.4861 1.78613 12.1872 1.95872C11.8882 2.13131 11.7858 2.51355 11.9584 2.81248L13.3416 5.20832H6.6577L8.04094 2.81248ZM15.1109 9.789C15.074 9.79076 15.037 9.79165 14.9997 9.79165H4.99967C4.96239 9.79165 4.92531 9.79076 4.88845 9.789L5.33742 12.8753L5.70112 14.5794C5.81444 15.1103 6.24786 15.5136 6.78558 15.5884C8.91804 15.8851 11.0813 15.8851 13.2138 15.5884C13.7515 15.5136 14.1849 15.1103 14.2982 14.5794L14.6619 12.8753L15.1109 9.789ZM3.95801 7.49998C3.95801 6.92469 4.42438 6.45832 4.99967 6.45832H14.9997C15.575 6.45832 16.0413 6.92469 16.0413 7.49998C16.0413 8.07528 15.575 8.54165 14.9997 8.54165H4.99967C4.42438 8.54165 3.95801 8.07528 3.95801 7.49998Z"
                    fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name : 'CatalogItem',
  props: ['item', 'categoryObject'],
  data() {
    return {
      order      : {
        count          : 1,
        selectedMod    : 0,
        additionSelect : [],
        additionUSelect: []
      }
    }
  },
  computed: {
    ml() {
      return this.weight?.length ? this.weight.includes('мл') || this.weight.includes('ml') : false
    },
    mlText() {
      return this.ml ? this.$static('fluid', 'Об’єм') : this.$static('weight', 'Вага')
    },
    weight() {
      return this.item?.advanced?.modList?.length ? this.item.advanced.modList[this.order.selectedMod].gram : ''
    },
    ingredient() {
      return this.item?.advanced?.options?.ingredient ? this.item?.advanced?.options?.ingredient.map(id => (
          this.ingridients?.list.find(x => x.id === id)
      )).filter(i => !!i) : []
    },
    ingridients() {
      return this.$store.getters.getOptions?.ingredient ? this.$store.getters.getOptions.ingredient : {}
    },
    price() {
      if (this.order.selectedMod > 0) {
        return this.item?.advanced?.modList[this.order.selectedMod].price ? this.item.advanced.modList[this.order.selectedMod].price : 0
      }
      return this.item.price
    },
    price_old() {
      if (this.order.selectedMod > 0) {
        return this.item?.advanced?.modList[this.order.selectedMod].price_old ? this.item.advanced.modList[this.order.selectedMod].price_old : 0
      }
      return this.item.price_old
    },
    spicy() {
      return this.item?.advanced?.s_level
    },
    isHot() {
      return this.item.advanced && this.item.advanced.hot
    },
    getDiscount() {
      return this.item.price_old ? (1 - Math.max(this.item.price / this.item.price_old, 0)) * 100 | 0 : ''
    },
    isOptions(){
      return this.item?.advanced?.uOptions?.length || this.item?.advanced?.options ? Object.keys(this.item?.advanced?.options).length : false
    }
  },
  methods : {
    buy() {
      let idx = -1;
      idx     = this.$store.getters.getBuskedSelected.findIndex(i => i.id === this.item.id);
      if (idx > -1) {
        // this.basked[idx].count += this.order.count;
        this.$store.commit(
            'replaceBuskedIndex',
            {index: idx, value: JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))}
        );
      } else {
        // console.log(JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item, {sizeSelect: this.sizeSelected}))));
        let item = JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))
        this.$store.commit('addToBasked', item);
        this.$AddToCart(item);
      }
      window.$('body').addClass('no-scroll');
      window.$('#quick_cart').addClass('opened');
      window.$('.frame-modal-wrapper-bg').addClass('is-visible');
    },
  }
}
</script>

<style scoped>
.catalog-item .not-available .options,
.catalog-item .not-available .product-name {
  opacity : 0.6;
}

.catalog-item .not-available .image {
  filter : grayscale(100%);
}
</style>