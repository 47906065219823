<template>
  <div class="order-wrapper wrapper">
    <div class="order-tab-buttons" v-if="busked?.length > 1">
      <ul>
        <li v-for="(_, orderIndex) in busked" :key="orderIndex">
          <a href="javascript:void(0)" @click="buskedSelect(orderIndex)" :class="{'active': selectedBusked===orderIndex}">
            Замовлення #{{ orderIndex + 1 }}
          </a>
        </li>
      </ul>
    </div>

    <!--    <div class="name h2 hidden-on-desk">{{ $static('order.title', 'Оформлення замовлення:') }}</div>-->
    <div class="order-desktop-pull hidden-on-mobile" v-if="upsale.length">
      <div class="panel">
        <div class="order-related-products related-products">
          <div class="name h2">{{ $static('order.related', 'Можливо, вас зацікавить:') }}</div>
          <ul class="catalog-grid" :class="{'smallest-grid': upsale.length < 3}" @mousedown="e => mouseDownHandlerTarget(e,'catalog-grid')">
            <div class="catalog-item" v-for="(i, index) in upsale" :key="index">
              <UpsaleItem :item="i"></UpsaleItem>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <div class="order-desktop-push" :class="{'related-empty': !upsale.length}">
      <div class="personal-data">
        <h3>{{ $static('order.b1', '1. Персональні дані') }}</h3>
        <div class="panel-bordered">
          <div class="form-group" :class="{'error': errors['name']}" v-if="buskedSettings.name">
            <label class="label-custom">
              {{ $static('order.name', 'Ім\'я') }}<span v-if="buskedSettings.name_require">*</span>
            </label>
            <input type="text" @focus="delError('name')" class="form-control custom-input"
                   :placeholder="$static('order.enter-name', 'Введіть ваше ім\'я')"
                   v-model="order.name" name="name">
            <p class="help-text" v-for="(err,key) in errors['name']" :key="key">{{ err }}</p>
          </div>

          <div class="form-group" :class="{'error': errors['sname']}" v-if="buskedSettings.sname">
            <label class="label-custom">
              {{ $static('order.surname', 'Прізвище') }}<span v-if="buskedSettings.sname_require">*</span>
            </label>
            <input type="text" @focus="delError('sname')" class="form-control custom-input"
                   :placeholder="$static('order.enter-surname', 'Введіть ваше прізвище')"
                   v-model="order.surname" name="second_name">
            <p class="help-text" v-for="(err,key) in errors['sname']" :key="key">{{ err }}</p>
          </div>

          <div class="form-group" :class="{'error': errors['phone']}" v-if="buskedSettings.phone || !isWebApp">
            <label class="label-custom">
              {{ $static('order.phone', 'Телефон') }}<span v-if="buskedSettings.phone_require || !isWebApp">*</span>
            </label>
            <input type="text" @focus="delError('phone')" class="form-control custom-input"
                   :placeholder="$static('order.enter-phone', '+380998787858')" v-model="order.phone" name="phone">
            <p class="help-text" v-for="(err,key) in errors['phone']" :key="key">{{ err }}</p>
          </div>

          <div class="form-group" :class="{'error': errors['email']}" v-if="buskedSettings.email">
            <label class="label-custom">
              {{ $static('order.email', 'Email') }}<span v-if="buskedSettings.email_require">*</span>
            </label>
            <input type="text" @focus="delError('email')" class="form-control custom-input"
                   :placeholder="$static('order.enter-email', 'Введіть ваш email')"
                   v-model="order.email"
                   name="email">
            <p class="help-text" v-for="(err,key) in errors['email']" :key="key">{{ err }}</p>
          </div>
        </div>
      </div>

      <div class="delivery-data" >
        <h3>{{ $static('order.b2', 'Доставка') }}</h3>
        <p class="help-text" v-for="(err,key) in errors['delivery_info']" :key="key">{{ err }}</p>

        <div class="delivery-type">
          <div class="selector-body">
            <div class="option-group">
              <div class="empty-text" v-if="delivery.length === 0">
                <p>{{ $static('order.empty-delivery', 'У вас немає активованих способів доставки') }}</p>
              </div>
              <div class="option-item" v-for="(deli, index) in delivery" :key="index" @click="this.order.delivery_id = deli.id"
                   :class="{'active': this.order.delivery_id === deli.id}">
                <div class='option-item-body'>
                  <p>{{ $dynamic(deli.manifest, deli.manifest.title, 'title') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="deliveryInfo && deliveryInfo.manifest">
          <div v-if="deliveryInfo.manifest.map">
            <div class="choose-department panel search-storehouse">
              <h3 class="uppercase" :class="{'error': errors.storehouse}">{{ $static('storehouse.department-require-error', 'Оберіть відділення ресторану') }} <span class="red">*</span></h3>
              <select class="form-control custom-input" v-model="house_id" @change="delError('storehouse')">
                <option :value="null">Найближчий</option>
                <option v-for="(s, index) in storehouse" :key="index" :value="s.id">{{ $dynamic(s, s.name, 'name') }}</option>
              </select>
              <p class="help-text" v-for="(err,key) in errors['storehouse']" :key="key">{{ err }}</p>
              <div v-if="house_id === null" class="stub-department">
                {{ selected.name }}
              </div>
              <div class="department-image image bg-cover" v-if="selected" :style="{'background-image': $filters.backgroundImage(selected.preview)}"></div>
            </div>

            <div class="map-wrapper">
              <MapSelector v-model="deliveryPoint" :point="point" :storehouses="storehouse"/>
            </div>
          </div>
          <div class="choose-department panel search-storehouse" v-else-if="storehouse.length">
            <h3 class="uppercase" :class="{'error': errors.storehouse}">{{ $static('storehouse.department-require-error', 'Оберіть відділення ресторану') }} <span class="red">*</span></h3>
            <select class="form-control custom-input" v-model="house_id" @change="delError('storehouse')">
              <option v-for="(s, index) in storehouse" :key="index" :value="s.id">{{ $dynamic(s, s.name, 'name') }}</option>
            </select>
            <p class="help-text" v-for="(err,key) in errors['storehouse']" :key="key">{{ err }}</p>
            <div class="map-wrapper">
              <Map :point="point" :storehouses="storehouse"></Map>
            </div>
            <!--            <div class="department-image image bg-cover" v-if="selected" :style="`background-image: url(${selected.preview});`"></div>-->
          </div>
          <div id="scrollEmptyAddress"></div>
          <template v-if="deliveryInfo.manifest.address">
            <label class="label-custom">
              {{ $static('order.address', 'Адреса') }}<span>*</span>
            </label>
            <div class="form-row row">
              <div class="form-group" :class="{'error': errors['delivery_address']}" style="width: 100%">
                <input @focus="delError('delivery_address')" class="form-control custom-input" placeholder="..."
                       v-model="deliveryInfo.address">
                <p class="help-text" v-for="(err,key) in errors['delivery_address']" :key="key">{{ err }}</p>
              </div>
            </div>
          </template>

          <template v-if="deliveryInfo.manifest.floor">
            <label class="label-custom">
              {{ $static('order.floor', 'Поверх') }}<span>*</span>
            </label>
            <div class="form-row row">
              <div class="form-group" :class="{'error': errors['delivery_floor']}" style="width: 100%">
                <input @focus="delError('delivery_floor')" class="form-control custom-input" placeholder="..."
                       v-model="deliveryInfo.floor">
                <p class="help-text" v-for="(err,key) in errors['delivery_floor']" :key="key">{{ err }}</p>
              </div>
            </div>
          </template>

          <template v-if="deliveryInfo.manifest.apartment">
            <label class="label-custom">
              {{ $static('order.apartment', 'Номер квартири') }}<span>*</span>
            </label>
            <div class="form-row row">
              <div class="form-group" :class="{'error': errors['delivery_apartment']}" style="width: 100%">
                <input @focus="delError('delivery_apartment')" class="form-control custom-input" placeholder="..."
                       v-model="deliveryInfo.apartment">
                <p class="help-text" v-for="(err,key) in errors['delivery_apartment']" :key="key">{{ err }}</p>
              </div>
            </div>
          </template>
        </template>
      </div>

      <OrderInfo :busked="buskedSelected" :order="order"/>

      <div class="hidden-on-desk">
        <div class="order-related-products related-products">
          <div class="name h2">{{ $static('order.related', 'Можливо, вас зацікавить:') }}</div>
          <ul class="catalog-grid">
            <li class="catalog-item" v-for="(i, index) in upsale" :key="index">
              <UpsaleItem :item="i"></UpsaleItem>
            </li>
          </ul>
        </div>
      </div>

      <!--      <div class="name h2 hidden-on-mobile">{{ $static('order.title', 'Оформлення замовлення:') }}</div>-->

      <div class="delivery-data" v-if="deliveryInfo?.manifest">
        <div class="form-row row" v-if="deliveryInfo.manifest.datetime">
          <div class="form-group">
            <label class="label-custom">
              Дата
            </label>
            <select class="form-control custom-input" v-model="deliveryModify.day">
              <option v-for="(day, index) in days" :value="index" :key="index">{{ day }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="label-custom">
              Час
            </label>
            <div class="form-row row">
              <select class="form-control custom-input" v-model="deliveryModify.time">
                <option v-for="(t, index) in times" :key="index" :value="index">{{ t.label }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="deliveryInfo.manifest.count">
          <label class="label-custom">
            Кількість персон
          </label>
          <select class="form-control custom-input" v-model="group_size">
            <option :value="'1'">на одного</option>
            <option :value="'2'">на двох</option>
            <option :value="'3-5'">3-5</option>
            <option :value="'5+'">5+</option>
          </select>
        </div>
        <div class="checkbox-group">
          <div class="default-checkbox" v-if="deliveryInfo.manifest.nocall">
            <input type="checkbox" id="no_call" name="no_call" v-model="deliveryModify.no_call">
            <label for="no_call">Не дзвонити для піддтвердження</label>
          </div>
          <div class="default-checkbox" v-if="deliveryInfo.manifest.leave">
            <input type="checkbox" id="leave_door" name="leave_door" v-model="deliveryModify.leave">
            <label for="leave_door">Залишити біля дверей</label>
          </div>
        </div>
      </div>

      <div class="comment-data" :class="{'error': errors['comment']}" v-if="buskedSettings.comment">
        <h3>{{ $static('order.b4', '4. Коментар') }}</h3><span v-if="buskedSettings.comment_require">*</span>
        <div class="comment-group	form-group">
        <textarea @focus="delError('comment')" class="form-control custom-input"
                  :placeholder="$static('order.comment', 'Введіть текст коментару')"
                  v-model="order.comment"></textarea>
          <p class="help-text" v-for="(err,key) in errors['comment']" :key="key">{{ err }}</p>
        </div>
      </div>
      <!--      <div class="order-promocode-panel">-->
      <!--        <label class="label-custom">-->
      <!--          Введіть промокод-->
      <!--        </label>-->
      <!--        <div class="form-group">-->
      <!--          <input type="text" class="form-control custom-input">-->
      <!--          <button class="default-btn btn-no-icon promocode-btn">Активувати</button>-->
      <!--        </div>-->
      <!--      </div>-->
      <div v-for="(order, orderIndex) in busked" :key="orderIndex">
        <div class="panel-ticket-title" v-if="busked.length > 1">Замовлення {{ orderIndex + 1 }}</div>

        <div class="order-info order-ticket panel-bordered">
          <div class="title">{{ $static('order.info-ticket', 'Товари в замовленні:') }}</div>
          <div class="cart-item ">
            <div class="item-holder" v-for="(item, index) in order" :key="index">
              <div class="item-info">
                <div class="right-side">
                  <div class="product">
                    <div class="product-name">{{ $dynamic(item, item.title, 'title') }}</div>
                    <div class="value" v-if="price(item) > 0">{{ item.count }} х {{ $filters.money(price(item)) }}
                      {{ $store.getters.getSettings.symbol }}
                    </div>
                  </div>
                  <div class="additional" v-if="item.additionUSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                      <div class="add-name">{{ m.name }}</div>
                      <div class="value" v-if="m.price">{{ item.count }} х {{ m.price }} {{ m.old_price }}
                        {{ $store.getters.getSettings.symbol }}
                      </div>
                    </div>
                  </div>
                  <div class="additional" v-if="item.additionSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                      <div class="add-name">{{ m.name }}</div>
                      <div class="value" v-if="m.price">{{ item.count }} х {{ m.price }} {{ $store.getters.getSettings.symbol }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="order-related-info" v-if="toPointDelivery && deliveryPoint">
          <ul v-if="orderInfo[orderIndex]?.zone" :set="info = orderInfo[orderIndex]">
            <li>
              <div class="name">Зона доставки:</div>
              <div class="value">{{ info.zone.name }}</div>
            </li>
            <li>
              <div class="name">Мінімальне замовлення:</div>
              <div class="value">{{ info.zone.minimum }} {{ $store.getters.getSettings.symbol }}</div>
            </li>
            <li v-if="info.zone.freePlus !== -1">
              <div class="name">Безкоштовна доставка:</div>
              <div class="value">від {{ info.zone.freePlus }} {{ $store.getters.getSettings.symbol }}</div>
            </li>
            <li>
              <div class="name">Орієнтовний час доставки:</div>
              <div class="value">{{ info.zone.deliveryText }}</div>
            </li>
            <li>
              <div class="name">Ціна доставки:</div>
              <div class="value" v-if="!deliveryPrices[orderIndex]">безкоштовно</div>
              <div class="value" v-else>{{ info.zone.deliveryPrice }} {{ $store.getters.getSettings.symbol }}</div>
            </li>
            <!--          <li>-->
            <!--            <div class="name">Знижка по промокоду:</div>-->
            <!--            <div class="value">-50 грн</div>-->
            <!--          </li>-->
          </ul>
          <div class="panel-bordered" v-if="deliveryPrices[orderIndex] || upsaleContinue">
            <h3 class="name">Замовте ще, щоб отримати безкоштовну доставку</h3>
            <div class="order-related-products related-products upsales-products" @click="upsaleContinue = true">
              <ul class="catalog-grid">
                <li class="catalog-item" v-for="(i, index) in upsale" :key="index">
                  <UpsaleItem :item="i"></UpsaleItem>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="!(orderInfo[orderIndex]?.zone)">
            Поза зоною доставки
          </div>
        </div>
      </div>


      <div class="payment-data">
        <h3>{{ $static('order.b3', 'Оплата') }}</h3>
        <p class="help-text" v-for="(err,key) in errors['pay_type']" :key="key">{{ err }}</p>
        <div class="option-group">
          <div class="empty-text" v-if="payment.length === 0">
            <p>{{ $static('order.empty-pay', 'У вас немає активованих способів оплати') }}</p>
          </div>
          <div class="option-item" v-for="(pay,index) in payment" :key="index" @click="order.pay_type = pay.id"
               :class="{'active': order.pay_type === pay.id}">
            <div class='option-item-body'>
              <p>{{ $dynamic(pay.manifest, pay.manifest.title, 'title') }}</p>
            </div>
          </div>
        </div>

        <div class="form-group" v-if="account">
          <label class="label-custom">
            {{ $static('order.bonus_discount', 'Розрахуватись бонусами: ') }}
            <span v-if="settings?.max_bonus && settings?.max_bonus !== 100">не більше ніж {{ settings?.max_bonus }}% від суми</span>
          </label>
          <div class="input-btn-group">
            <button class="default-btn" @click="setBonusMax">
              <span class="btn-text">Max {{ maxBonus }}</span>
            </button>
            <input type="text" class="form-control custom-input" :placeholder="0" v-model.number="order.bonus_discount">
          </div>
        </div>
        <div v-else>
          <label class="label-custom">
            {{ $static('order.bonus_discount', 'Розрахуватись бонусами: ') }} <span>Необхідна авторизація</span>
          </label>
        </div>
      </div>

      <div class="order-total panel" v-if="busked.length">
        <div class="name">{{ $static('order.total', 'Всього до сплати') }}:</div>
        <div class="value">
          <div class="price-element">{{ $filters.money(totalPrice) }} {{ $store.getters.getSettings.symbol }}</div>
        </div>
      </div>

      <div class="order-buttons" v-if="deliveryInfos[selectedBusked].buskedTotal >= minPrice">
        <button class="default-btn" @click.prevent.stop="makeOrder()">
          <span class="btn-text" v-if="isDeliveryCustom">{{ $static('order.makeOrder', 'Замовити') }}</span>
          <span class="btn-text" v-else>{{ $static('order.pay', 'Сплатити') }}</span>
        </button>

        <div class="link-holder">
          <router-link :to="{name: 'home'}" class="back-link">
            <img src="images/icons/left.svg" alt="">
            <span class="text">{{ $static('order.back', 'Назад у магазин') }}</span>
          </router-link>
        </div>
      </div>
      <div class="div" v-else>
        <div class="name" v-if="deliveryInfos.length > 1">Замовлення #{{ selectedBusked }}.</div>
        <div class="value">Мінімальна сумма замовлення: {{ minPrice }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import Map from '@/components/Map';
import MapSelector from '@/components/MapSelector';
import OrderInfo from '@/views/OrderInfo';
import UpsaleItem from '@/views/UpsaleItem';
import axios from 'axios';

// import SearchSelect from '../components/SearchSelect';

function isMarkerInsidePolygon(marker, poly) {
  let inside = false;
  let x      = marker.lat, y = marker.lng;
  for (let ii = 0; ii < poly.length; ii++) {
    let polyPoints = poly[ii];
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      let xi = polyPoints[i].lat, yi = polyPoints[i].lng;
      let xj = polyPoints[j].lat, yj = polyPoints[j].lng;

      let intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
  }

  return inside;
}

function format(time) {
  let minutes = ('0' + time.getMinutes()).slice(-2)
  let hours   = ('0' + time.getHours()).slice(-2)
  return `${hours}:${minutes}`
}

export default {
  name      : 'Order',
  components: {Map, OrderInfo, MapSelector, UpsaleItem},
  data() {
    return {
      page          : 1,
      perPage       : 11,
      totalItems    : 0,
      more          : true,
      errors        : {},
      upsale        : [],
      stopList      : [],
      upsaleContinue: false,
      deliveryPoint : undefined,
      deliveryModify: {day: 0, time: 0, no_call: false, leave: false},
      houseSelected : [null, null],
      group_size    : '1',
      order         : {
        name          : '',
        surname       : '',
        phone         : '',
        comment       : '',
        pay_type      : undefined,
        bonus_discount: 0,
        delivery_id   : undefined
      }
    }
  },
  mounted() {
    this.$OrderPage()
    this.getItems()

    let orderInit = localStorage.getItem('_orderInit')
    if (orderInit) {
      Object.assign(this.order, JSON.parse(orderInit))
      this.order.bonus_discount = 0
    }
    let checkPoint = window.localStorage.getItem('_dPoint')
    if (checkPoint) {
      try {
        let point = JSON.parse(checkPoint)
        if (point) {
          this.deliveryPoint = point
        }
      } catch (err) {
        console.error(err)
      }
    }

    document.title = 'Order';
    fetch('/public/stop_list').then(response => response.json()).then(data => {
      if (data?.length) {
        this.stopList = data
      }
    });

    if (this.storehouse.length && this.deliveryPoint) {
      this.distanceCalc(this.deliveryPoint)
    }
    this.validateDelivery(this.defaultDelivery)
    this.validatePayment(this.defaultPayment)

    // if (this.payment.length) {
    //   let payDefault = this.payment.find(i => i.settings.default);
    //   if (payDefault) {
    //     this.order.pay_type = payDefault
    //   } else {
    //     this.order.pay_type = this.payment[0]
    //   }
    // }
  },
  computed: {
    minPrice() {
      return this.toPointDelivery && this.deliveryInfos[this.selectedBusked]?.zone?.minimum ? this.deliveryInfos[this.selectedBusked].zone.minimum : 0
    },
    account() {
      return this.$store.getters.getAccount
    },
    house_id: {
      get() {
        return this.houseSelected[this.selectedBusked]
      },
      set(value) {
        this.houseSelected[this.selectedBusked] = value
      }
    },
    storehouse() {
      return this.$store.getters.getStorehouse.filter(i => !i.disabled).filter(house => {
        if (house.info?.categories) {
          // console.log(house.name, house.info?.categories, this.buskedCategories, !this.buskedCategories.some(a => {
          //   console.log(a)
          //   return house.info.categories.findIndex(b => a === b) < 0
          // }))
          // console.log(house.info.categories, this.buskedCategories, house.info.categories.some(a => this.buskedCategories.findIndex(b => a === b) < 0))
          return !this.buskedCategories.some(a => house.info.categories.findIndex(b => a === b) < 0)
        }
        return true
      })
    },
    delivery() {
      return this.$store.getters.getDelivery
    },
    payment() {
      return this.$store.getters.getPayment
    },
    defaultDelivery: {
      get() {
        return this.$store.getters.getDefaultDelivery
      },
      set(value) {}
    },
    defaultPayment : {
      get() {
        return this.$store.getters.getDefaultPayment
      },
      set(value) {}
    },
    toPointDelivery() {
      return this.deliveryInfo?.manifest?.map
    },
    orderInfo() {
      if (this.toPointDelivery) {
        return this.busked.map((_, index) => {
          let house = this.houseSelected[index]
          if (!house) {
            house = this.storehouse.filter(house => {
              return !this.stopList.filter(i => i.storehouse === house.id)
                  .find(stop => this.busked[index].some(item => stop.item === item.id))
            })
            if (house.length) {
              house = house.reduce((prev, zone) => zone.distance < prev.distance ? zone : prev)
            } else {
              return []
            }
          } else {
            house = this.storehouse.find(value => value.id === house)
          }
          if (!this.deliveryPoint) {
            return Object.assign({storehouse: house}, {zone: undefined})
          }
          let result = house.info.point.zone.filter(zone => {
            return zone.polygon.some(p => isMarkerInsidePolygon(this.deliveryPoint, p))
          })
          if (result && result.length) {
            result = Object.assign(
                {storehouse: house},
                {zone: result.reduce((prev, zone) => zone.deliveryPrice < prev.deliveryPrice ? zone : prev)}
            )
          }
          return result
        })
      }
      return this.busked.map((_, index) => this.houseSelected[index] ?
          {storehouse: this.storehouse.find(s => s.id === this.houseSelected[index])}
          : undefined)
    },
    selected() {
      if (this.selectedBusked === 0) {
        return this.house_id ?
            this.storehouse.find(x => x.id === this.house_id) :
            this.storehouse.reduce(
                (prev, store) => store.distance < prev.distance ? store : prev,
                Object.assign({distance: 2e8}, this.storehouse[0])
            )
      } else {
        return this.house_id ?
            this.storehouse.find(x => x.id === this.house_id) :
            this.availableStores.reduce(
                (prev, store) => store.distance < prev.distance ? store : prev,
                Object.assign({distance: 2e8}, this.availableStores[0])
            )
      }
    },
    buskedCategories() {
      return this.busked.reduce((prev, next) => {
        return [...prev, ...next.map(item => parseInt(item.category, 10))]
      }, []).filter((value, index, array) => array.indexOf(value) === index);
    },
    availableStores() {
      return this.storehouse.filter(house => {
        return !this.stopList.filter(i => i.storehouse === house.id)
            .find(stop => this.busked[this.selectedBusked].some(item => stop.item === item.id))
      })
    },
    point() {
      return this.house_id && this.selected?.info?.point ? this.selected.info.point : undefined
    },
    isDeliveryCustom() {
      if (this.order && this.order.pay_type) {
        let d = this.payment.find(x => x.id === this.order.pay_type)
        if (d && d.name === 'custom') {
          return true
        }
      }
      return false
    },
    isWebApp() {
      return this.$store.getters.isWebApp
    },
    buskedSettings() {
      return this.settings && this.settings.busked ? this.settings.busked : {}
    },
    commentDisable() {
      return this.buskedSettings.comment
    },
    busked() {
      return this.$store.getters.getBusked.map(i => {
        let find = this.stopList.find(s => s.item === i.id)
        if (find) {
          return Object.assign(i, {stop: find})
        }
        return i
      })
    },
    selectedBusked() {
      return this.$store.getters.getSelected
    },
    buskedSelected() {
      return this.$store.getters.getBuskedSelected.map(i => {
        let find = this.stopList.find(s => s.item === i.id && s.storehouse === this.selected.id)
        if (find) {
          i.stop = find
        } else if (i.stop) {
          delete i.stop
        }
        return i
      })
    },
    total() {
      return this.$store.getters.getBuskedTotal
    },
    totalPrice() {
      return this.$store.getters.getBuskedTotal + this.deliveryPrices.reduce((prev, next) => next + prev, 0)
    },
    deliveryInfos() {
      return this.orderInfo.map((i, index) => ({
        zone       : i ? i.zone : i,
        buskedTotal: this.busked[index].reduce((prev, item) => prev + window.itemPrice(item), 0)
      }))
    },
    deliveryPrices() {
      return this.deliveryInfos.map(x => (x.zone?.freePlus && (x.zone.freePlus === -1 || x.buskedTotal < x.zone.freePlus) ? x.zone.deliveryPrice : 0))
    },
    settings() {
      return this.$store.getters.getSettings
    },
    BOT_NAME() {
      return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
    },
    deliveryInfo() {
      return this.delivery && this.order.delivery_id ? this.delivery.find(x => x.id === this.order.delivery_id) : undefined
    },
    days() {
      return this.deliverySchedule.map((i, index) => index ? i.today.toLocaleDateString() : 'сьогодні')
    },
    times() {
      return this.deliverySchedule[this.deliveryModify.day] ? this.getTimes(this.deliverySchedule[this.deliveryModify.day]) : [{0: 'як найшвидше'}]
    },
    deliverySchedule() {
      if (!this.selected?.info?.schedule) {
        return []
      }
      let schedule = this.selected.info.schedule
      let today    = new Date()
      let result   = [];
      for (let i = 0; i < 6; i++) {
        let day = today.getDay() - 1;
        if (day < 0) {day = 6}
        let check = schedule.filter(i => i.days.some(x => x === day));
        if (check && check.length) {result.push({today: today, check: check, day: day})}

        today = new Date(today.getTime() + 86400000);
        today.setHours(0)
        today.setMinutes(0)
        today.setSeconds(0)
      }
      return result
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    bonus() {
      return this.account?.bonus
    },
    maxDiscountByPrice() {
      return this.totalPrice * (this.settings?.max_bonus ? (Math.min(Math.max(this.settings.max_bonus, 0), 100) / 100) : 0)
    },
    maxBonus() {
      return this.bonus ? Math.floor(Math.min(this.maxDiscountByPrice, this.bonus)) : 0
    }
  },
  methods : {
    validatePayment(def) {
      if (this.order.pay_type && this.payment.some(i => i.id === this.order.pay_type)) {
        return
      }
      if (def) {
        this.order.pay_type = this.defaultPayment
      }
    },
    validateDelivery(def) {
      if (this.order.delivery_id && this.delivery.some(i => i.id === this.order.delivery_id)) {
        return
      }
      if (def) {
        this.order.delivery_id = this.defaultDelivery
      }
    },

    buskedSelect(index) {
      this.$store.commit('setSelected', index)
    },
    getTimes(result) {
      let now   = result.today;
      let start = new Date(now.getTime() - now.getTime() % 900000);
      let next  = [];
      let day   = new Date().getDay() - 1;
      if (day < 0) {day = 6}
      if (day === result.day) {
        next = [{value: 0, label: 'як найшвидше'}];
      }
      result.check.sort(a => a.on)
      result.check.forEach(c => {
        let on  = new Date(this.stamp(c.on)).getHours()
        let off = new Date(this.stamp(c.off)).getHours()
        while (start.getHours() < off) {
          start.setTime(start.getTime() + 900000);
          if (start.getHours() < on) {
            continue
          }
          next.push({value: start.getTime(), label: format(start)});
        }
      })
      return next
    },
    stamp(a) {
      return new Date(`Thu Jan 01 1970 ${a}:00`).getTime()
    },
    getDistance(origin, destination) {
      let lon1 = this.toRadian(origin[1]),
          lat1 = this.toRadian(origin[0]),
          lon2 = this.toRadian(destination[1]),
          lat2 = this.toRadian(destination[0]);

      let deltaLat = lat2 - lat1;
      let deltaLon = lon2 - lon1;

      let a            = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
      let c            = 2 * Math.asin(Math.sqrt(a));
      let EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    },
    toRadian(degree) {
      return degree * Math.PI / 180;
    },
    distanceCalc(point) {
      this.storehouse.map(store => {
        let p2 = store?.info?.point
        if (p2) {
          store.distance = this.getDistance([p2.lat, p2.lng], [point.lat, point.lng]);
          // console.log(store, store.distance)
        }
        return store
      })
    },
    telegramLoadedCallbackFunc() {
      // console.log('script is loaded')
      this.isLoaded = true
    },
    mouseDownHandlerTarget(e, targetClass) {
      let target = e.target;
      while (target.className?.includes && !target.className.includes(targetClass)) {
        target = target.parentElement
      }
      window.ele = target;
      window.mouseDownHandler(e)
    },
    getItems(clear) {
      if (this.load) {
        return
      }
      this.load  = true;
      let filter = {priority: true, perPage: this.perPage, page: this.page};
      axios.post('/public/item/filter', {filter: filter}).then(response => response.data).then(data => {
        this.load = false;
        if (clear) {
          this.upsale = data.result
        } else {
          this.upsale.push(...data.result);
        }
        this.page       = data.page;
        this.totalItems = data.total;
        if (this.upsale.length === this.totalItems) {
          this.more = false
        }
      }).catch(() => {
        this.load = false;
      })
    },
    price(item) {
      return window.selectedPrice(item)
    },
    setBonusMax() {
      this.order.bonus_discount = this.maxBonus
    },
    makeOrder(user) {
      window.scrollTarget = undefined
      let valid           = this.validate();
      if (!valid) {
        if (window.scrollTarget) {
          window.scrollTo({
                            top     : window.scrollTarget.offsetTop,
                            behavior: 'smooth'
                          });
          window.scrollTarget = undefined
        } else {
          window.scrollTo(0, 0);
        }
        return
      }
      window.localStorage.setItem('_orderInit', JSON.stringify(this.order))

      let delivery = undefined;
      if (this.toPointDelivery) {
        delivery           = this.deliveryPoint
        delivery.floor     = this.deliveryInfo.floor
        delivery.apartment = this.deliveryInfo.apartment
      } else {
        delivery = JSON.parse(JSON.stringify(this.deliveryInfo))
        delete delivery.manifest
        delete delivery.name
        delete delivery.type
        delete delivery.widget
        delete delivery.integrate
        delete delivery.status
        delete delivery.order
        delete delivery.id
      }
      let order = Object.assign({}, this.order, {
        leave      : this.deliveryModify.leave,
        group_size : this.group_size,
        no_call    : this.deliveryModify.no_call,
        day        : this.days[this.deliveryModify.day],
        time       : this.times[this.deliveryModify.time].label,
        storehouses: this.orderInfo.map(i => i.storehouse.id),
        delivery   : delivery,
        busked     : this.busked.map(order => {
          return order.map(i => {
            return {
              id      : i.id,
              mod     : i.selectedMod,
              count   : i.count,
              add     : i.additionSelect?.length ? i.additionSelect.map(i => i.id) : [],
              u_select: i.additionUSelect?.length ? i.additionUSelect : []
            }
          })
        })
      });

      if (order.pay_type && order.pay_type.name) {
        order.pay_type = order.pay_type.name
      }


      this.$Purchase(this.busked)

      if (user) {
        order.user = user
      }
      let ok        = false
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true
      }
      if (this.userInfo) {
        order.user = this.userInfo
        ok         = true
      }

      // console.log(order);
      // return;
      return fetch(
          '/public/order',
          {
            method : 'POST',
            body   : JSON.stringify(order),
            headers: headers
          }
      ).then(response => response.json()).then(data => {
        if (data.error) {
          window.$('#thx').removeClass('is-visible');
          window.$('#errorModal').addClass('is-visible');
          return
        }
        window.$('#thx').addClass('is-visible');
        if (initCache) {
          setTimeout(() => {
            window.Telegram.WebApp.close();
          }, 1000);
        }

        this.$store.commit('clearBasked');
        if (data.link) {
          setTimeout(() => {
            window.location.href = data.link;
          }, 1000);
        } else {
          setTimeout(() => {
            window.$('#thx').removeClass('is-visible');
            this.$router.push({name: 'home'});
          }, 3000);
        }
        return []
      }).catch((err) => {
        console.error(err);
        window.$('#thx').removeClass('is-visible');
        window.$('#errorModal').removeClass('is-visible');
      })

    },
    delError(key) {
      delete this.errors[key]
    },
    validate() {
      this.errors = {};

      if (this.busked.some(order => order.some(i => i.stop))) {
        this.errors.storehouse = [this.$static('storehouse.department-require-error', 'Оберіть відділення ресторану')];
        window.scrollTarget    = document.querySelector('.search-storehouse')
        return false
      }

      // if (this.house_id === null) {
      //   this.errors.storehouse = [this.$static('storehouse.department-require-error', 'Оберіть відділення ресторану')];
      //   window.scrollTarget    = document.querySelector('.search-storehouse')
      //   return false
      // }

      if (this.buskedSettings.name && this.buskedSettings.name_require) {
        if (this.order.name.length < 3) {
          this.errors.name = [this.$static('order.name-require-error', 'Ім\'я обов\'язкове')];
          return false
        }
      }
      console.log('c1')
      if ((this.buskedSettings.phone && this.buskedSettings.phone_require) || this.buskedSettings.anonymous) {
        if (this.order.phone.length < 8) {
          this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
          return false
        }
      }
      console.log('c2')
      if (this.buskedSettings.sname && this.buskedSettings.sname_require) {
        if (this.order.phone.length < 8) {
          this.errors.sname = [this.$static('order.name-require-error', 'Ім\'я обов\'язкове')];
          return false
        }
      }
      console.log('c3')
      if (this.buskedSettings.comment && this.buskedSettings.comment_require) {
        if (this.order.comment.length < 3) {
          this.errors.comment = [this.$static('order.comment-require-error', 'Коментар обов\'язковий')];
          return false
        }
      }

      console.log('c4')
      if (!this.deliveryInfo) {
        this.errors.delivery_info = [this.$static('order.delivery-info-error', 'Оберіть спосіб доставки')];
        window.scrollTarget       = document.querySelector('#scrollEmptyAddress')
        return false
      }

      if (this.deliveryInfo.type && this.deliveryInfo.manifest) {
        if (this.deliveryInfo.manifest.address && (this.deliveryInfo.address === undefined || this.deliveryInfo.address.length < 3)) {
          this.errors.delivery_address = [this.$static('order.delivery-address-error', 'Адреса обов\'язкова')];
          window.scrollTarget          = document.querySelector('#scrollEmptyAddress')
          return false
        }

        if (this.deliveryInfo.manifest.floor && (this.deliveryInfo.floor === undefined || this.deliveryInfo.floor.length < 1)) {
          this.errors.delivery_floor = [this.$static('order.delivery-floor-error', 'Поверх обов\'язковий')];
          window.scrollTarget        = document.querySelector('#scrollEmptyAddress')
          return false
        }

        if (this.deliveryInfo.manifest.apartment && (this.deliveryInfo.apartment === undefined || this.deliveryInfo.apartment.length < 1)) {
          this.errors.delivery_apartment = [this.$static('order.delivery-apartment-error', 'Номер квартири обов\'язковий')];
          window.scrollTarget            = document.querySelector('#scrollEmptyAddress')
          return false
        }
      }

      console.log('c5')

      if (this.toPointDelivery && !this.deliveryPoint) {
        // this.errors.storehouse = [this.$static('storehouse.comment-require-error', 'Оберіть точку')];
        window.scrollTarget = document.querySelector('.map-wrapper')
        return false
      }
      console.log('c51')
      if (this.storehouse?.length === 1){
        this.houseSelected = this.houseSelected.map(() => this.storehouse[0].id)
      }

      let checkX = this.orderInfo.findIndex(i => !i)
      if (checkX > -1) {
        this.buskedSelect(checkX)
        this.errors.storehouse = [this.$static('storehouse.department-require-error', 'Оберіть відділення ресторану')];
        window.scrollTarget    = document.querySelector('.search-storehouse')
        return false
      }
      console.log('c6')
      let checkUnavailable = this.orderInfo.map((info, index) => ({
        storehouse: info.id,
        items     : this.busked[index].map(item => item.id)
      }))
          .findIndex(order => order.items.some(item_id => this.stopList.find(stop => stop.storehouse === order.storehouse && stop.item === item_id)))
      if (checkUnavailable > -1) {
        this.buskedSelect(checkUnavailable)
        window.scrollTarget = document.querySelector('.order-info')
        return false
      }
      console.log('c7')

      if (this.toPointDelivery) {
        console.log(this.deliveryInfos);
        checkX = this.deliveryInfos.map(x => x.zone && x.buskedTotal > x.zone.minimum).findIndex(i => !i)

        if (checkX > -1) {
          this.buskedSelect(checkX)
          window.scrollTarget = document.querySelector('.order-buttons')
          return false
        }
      }
      console.log('c8')
      if (!this.order.pay_type) {
        this.errors.pay_type = [this.$static('order.pay_type-error', 'Оберіть спосіб оплати')];
        window.scrollTarget  = document.querySelector('.payment-data')
        return false
      } else {
        let check = this.payment.find(p => p.id === this.order.pay_type)
        if (!check) {
          this.errors.pay_type = [this.$static('order.pay_type-error', 'Оберіть спосіб оплати')];
          window.scrollTarget  = document.querySelector('.payment-data')
          return false
        }
      }
      console.log('c9')

      // this.orderInfo.filter()
      return true;
    }
  },
  watch   : {
    deliveryPoint(val) {
      this.distanceCalc(val)
      window.localStorage.setItem('_dPoint', JSON.stringify(val))
    },
    //WatchStorehouse
    storehouse(value) {
      if (this.deliveryPoint) {
        this.distanceCalc(this.deliveryPoint)
      }
    },
    defaultDelivery(val) {
      this.validateDelivery(val)
    },
    defaultPayment(val) {
      this.validatePayment(val)

    }
  }
}
</script>

